import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Box,
  ExpandableSection,
  Icon,
  Pagination,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import './ActivityAllocationGrid.css';
import { get } from 'lodash';
import { connect } from 'react-redux';
import AllocationGrid from '../../../AllocationGrid/AllocationGrid';

const ActivityAllocationGrid = ({
  entityFilter,
  entityData,
  projectList,
  activities,
  surveyType,
  surveyDetails,
  activityDualAllocation,
  setActivityDualAllocation,
  copiedValues,
  copiedColumn,
  setCopiedValues,
}) => {
  const [statusMap, setStatusMap] = useState({});
  const NO_DATA_FOUND_MESSAGE = 'No employee found matching the given criteria.';

  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const PAGE_SIZE = 10;

  const checkStatus = () => {
    const newStatusMap = {};

    entityData.forEach(entity => {
      const colsEntity = projectList[entity.id]
        ? projectList[entity.id].map(project => ({
            id: project.id,
            name: project.name,
          }))
        : [];

      if (colsEntity.length === 0) {
        newStatusMap[entity.id] = 'PENDING';
        return;
      }

      let allColumnsValid = true;
      let hasData = false;

      colsEntity.forEach(colEntity => {
        const total = activityDualAllocation?.data[entity.id]?.[colEntity.id]?.total
          ? parseFloat(activityDualAllocation?.data[entity.id][colEntity.id].total)
          : null;

        if (total === null || total === 0 || total !== 100) {
          allColumnsValid = false;
        } else {
          hasData = true;
        }
      });

      if (!hasData) {
        allColumnsValid = false;
      }

      newStatusMap[entity.id] = allColumnsValid ? 'SUCCESS' : 'ERROR';
    });

    setStatusMap(newStatusMap);
  };

  useEffect(() => {
    checkStatus();
  }, [activityDualAllocation]);

  const dataToBeRendered = useMemo(() => {
    setCurrentPageIndex(1);
    if (entityFilter === '') return entityData;
    return entityData.filter(entity => {
      return Object.values(entity)
        .join(' ')
        .toLowerCase()
        .includes(entityFilter);
    });
  }, [entityData, entityFilter]);

  const dataOnPage = useMemo(() => {
    return dataToBeRendered.slice(
      Math.imul(currentPageIndex - 1, PAGE_SIZE),
      Math.imul(currentPageIndex, PAGE_SIZE),
    );
  }, [dataToBeRendered, currentPageIndex]);

  const TOTAL_PAGE_COUNT = useMemo(() => {
    return Math.ceil(dataToBeRendered.length / PAGE_SIZE);
  }, [dataToBeRendered]);

  const getStatusIcon = status => {
    switch (status) {
      case 'SUCCESS':
        return <Icon name="status-positive" variant="success" size="big" />;
      case 'ERROR':
        return <Icon name="status-warning" variant="error" size="big" />;
      case 'PENDING':
      default:
        return <Icon name="status-positive" variant="disabled" size="big" />;
    }
  };

  return dataOnPage.length === 0 ? (
    <h3>
      <Alert type="warning">{NO_DATA_FOUND_MESSAGE}</Alert>
    </h3>
  ) : (
    <>
      <SpaceBetween size="l">
        <Box variant="h3" className="pagination-component">
          <Pagination
            currentPageIndex={currentPageIndex}
            onChange={({ detail }) => setCurrentPageIndex(detail.currentPageIndex)}
            pagesCount={TOTAL_PAGE_COUNT}
          />
        </Box>
        <div className="activity-allocation-grid">
          {dataOnPage.map(entity => (
            <ExpandableSection
              key={entity.id}
              headerText={
                <>
                  {entity.name && <span>{entity.name}</span>}
                  {entity.employeeAlias && (
                    <span className="employee-section-header-alias"> {entity.employeeAlias}</span>
                  )}
                </>
              }
              headerActions={getStatusIcon(statusMap[entity.id])}
              headerDescription={
                <SpaceBetween size="l" direction="horizontal">
                  {entity.jobTitle && <span>Job title: {entity.jobTitle}</span>}
                  {entity.empId && <span>Employee ID: {entity.empId}</span>}
                  {entity.supervisorName && (
                    <span>
                      {`Reports to: ${entity.supervisorName} ${
                        entity.supervisorAlias ? `(${entity.supervisorAlias})` : ''
                      }`}
                    </span>
                  )}
                  {entity.smeName && (
                    <span>
                      {`SME Name: ${entity.smeName} ${
                        entity.smeAlias ? `(${entity.smeAlias})` : ''
                      }`}
                    </span>
                  )}
                </SpaceBetween>
              }
              variant="container"
            >
              <AllocationGrid
                rowsEntity={activities}
                colsEntity={
                  projectList[entity.id]
                    ? projectList[entity.id].map(project => ({
                        id: project.id,
                        name: project.name,
                      }))
                    : []
                }
                gridValues={activityDualAllocation?.data[entity.id] || {}}
                setGridValues={val => {
                  const newAllocationData = {
                    ...activityDualAllocation,
                    data: {
                      ...activityDualAllocation?.data,
                      [entity.id]: val,
                    },
                  };
                  setActivityDualAllocation(newAllocationData);
                }}
                allocationType="Activity"
                surveyType={surveyType}
                surveyDetails={surveyDetails}
                entityForActivityAllocation={entity}
                copiedValues={copiedValues}
                copiedColumn={copiedColumn}
                setCopiedValues={setCopiedValues}
              />
            </ExpandableSection>
          ))}
        </div>
      </SpaceBetween>
    </>
  );
};

ActivityAllocationGrid.propTypes = {
  entityFilter: PropTypes.string.isRequired,
  entityData: PropTypes.array.isRequired,
  projectList: PropTypes.object.isRequired,
  activities: PropTypes.array.isRequired,
  surveyType: PropTypes.string.isRequired,
  surveyDetails: PropTypes.object.isRequired,
  activityDualAllocation: PropTypes.object.isRequired,
  setActivityDualAllocation: PropTypes.func.isRequired,
  copiedValues: PropTypes.object.isRequired,
  copiedColumn: PropTypes.string,
  setCopiedValues: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  activityDualAllocation: get(state, 'activityDualAllocation', {}),
});

export default connect(mapStateToProps)(ActivityAllocationGrid);
