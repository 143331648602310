import { isEmpty, get } from 'lodash';
import { PROJECT_TEMPLATE_IDS } from '../../../../common/constants/templateIds';
// import { getTotalStepCount } from '../../../survey_page/constants/SurveySteps';

// eslint-disable-next-line no-unused-vars
const StepsCompleted = (surveyType, userResponseString, templateId, derivedClientId) => {
  if (isEmpty(userResponseString)) return 0;
  let userResponse = null;
  try {
    userResponse = JSON.parse(userResponseString);
  } catch (error) {
    return 0;
  }

  const projectDocumentation = !PROJECT_TEMPLATE_IDS.includes(templateId);
  // let completedSteps = getTotalStepCount(templateId, surveyType, derivedClientId);

  let completedSteps = projectDocumentation ? 6 : 5;

  const activeStep = projectDocumentation ? 3 : 2;

  if (get(userResponse, 'Employee.error') || get(userResponse, 'Employee.excelError'))
    completedSteps -= 1;
  if (get(userResponse, 'Project.error')) completedSteps -= 1;
  if (get(userResponse, 'activeStep') < activeStep) completedSteps -= 1; // actvity phase

  if (projectDocumentation && get(userResponse, 'activeStep') < activeStep - 1) completedSteps -= 1;
  if (
    get(userResponse, 'ProjectAllocation.error') ||
    isEmpty(get(userResponse, 'ProjectAllocation.data'))
  )
    completedSteps -= 1;
  if (
    get(userResponse, 'ActivityAllocation.error') ||
    isEmpty(get(userResponse, 'ActivityAllocation.data'))
  )
    completedSteps -= 1;
  if (
    get(userResponse, 'ActivityDualAllocation.error') ||
    isEmpty(get(userResponse, 'ActivityDualAllocation.data'))
  )
    completedSteps -= 1;
  return completedSteps;
};
export default StepsCompleted;
