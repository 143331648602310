import { get, isNull, filter } from 'lodash';

export const mappedProjectToSelectedProject = ({
  projectId,
  projectDescription,
  projectName,
  isUserCreated,
}) => ({
  id: projectId,
  description: projectDescription,
  name: projectName,
  isUserCreated,
});

export const isProjectAllocationPresent = (state, projectId) => {
  const allocations = get(state, 'data');
  // eslint-disable-next-line
  for (const empId in allocations) {
    if (!isNull(get(allocations, [empId, projectId], null))) return true;
  }
  return false;
};

const getUnselectedProjects = (projects, selectedProjects) => {
  return filter(projects, project => {
    // eslint-disable-next-line no-restricted-syntax
    for (const selectedProject of selectedProjects) {
      if (selectedProject.id === project.id) {
        return false;
      }
    }
    return true;
  });
};

export const getSortedProjects = (allProjects, selectedProjects) => {
  if (isNull(allProjects) || isNull(selectedProjects)) {
    return [];
  }
  const unSelectedProjects = getUnselectedProjects(allProjects, selectedProjects);
  selectedProjects.sort((project1, project2) => {
    return project1.name > project2.name ? 1 : -1;
  });
  unSelectedProjects.sort((project1, project2) => {
    return project1.name > project2.name ? 1 : -1;
  });
  return selectedProjects.concat(unSelectedProjects);
};

export const sortFilteredProjects = (filteredProjects, selectedProjects) => {
  if (isNull(filteredProjects) || isNull(selectedProjects)) {
    return [];
  }
  const newSelectedProjects = filter(selectedProjects, project1 => {
    let isProjectSelected = false;
    // eslint-disable-next-line no-restricted-syntax
    for (const project2 of filteredProjects) {
      if (project1.id === project2.id) {
        isProjectSelected = true;
      }
    }
    return isProjectSelected;
  });
  return getSortedProjects(filteredProjects, newSelectedProjects);
};
