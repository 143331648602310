import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Header,
  SpaceBetween,
  Modal,
  FormField,
  Input,
  Link,
  Table,
  TextFilter,
  Pagination,
  CollectionPreferences,
} from '@amzn/awsui-components-react';
import { get, map, size, isEmpty, filter } from 'lodash';
import PropTypes from 'prop-types';
import { difference, unionBy } from 'lodash/array';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import { CREATE_PROJECT, VALARI_API } from '../../../../../common/config/api_endpoints';
import '../style.css';
import UncheckProjectModal from './UncheckProjectModal';
import {
  projectDataInitialState,
  deleteProjectModalInitialState,
  modalTypes,
} from '../constants/projects_config';
import {
  getSortedProjects,
  isProjectAllocationPresent,
  mappedProjectToSelectedProject,
  sortFilteredProjects,
} from '../utils/utils';
import addContextToPayload from '../../../../../common/utils/api_util';
import { getStudyPeriod } from '../../../../../common/constants/study_period';
import { setPageNotification } from '../../../../../common/components/with_page/redux/reducer';
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_INFO,
  NOTIFICATION_TYPE_SUCCESS,
} from '../../../../../common/components/with_page/notifications/constants';
import { isReadOnly } from '../../../utils/survey_page_utils';
import './Projects.css';

const Projects = ({
  state,
  setState,
  pageContents,
  setPageElements,
  surveyDetails,
  studyPeriod,
  pushNotification,
  projectAllocation,
  derivedClientId,
}) => {
  const [projectData, setProjectData] = useState(projectDataInitialState);
  const [deleteProjectModal, setDeleteProjectModal] = useState(deleteProjectModalInitialState);
  const selectedProjects = get(state, 'Project.data.selectedProjects', []);

  const listOfProjects = map(
    get(pageContents, 'Project.projectList', []),
    mappedProjectToSelectedProject,
  );

  const [visibleItems, setVisibleItems] = useState(
    getSortedProjects(listOfProjects, selectedProjects),
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [filteringText, setFilteringText] = useState('');

  const updatePreferences = event => {
    if (event.detail?.pageSize) {
      setItemsPerPage(event.detail.pageSize);
    }
  };

  useEffect(() => {
    setVisibleItems(getSortedProjects(listOfProjects, selectedProjects));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, pageContents]);

  const [combinedProjects, setCombinedProjects] = useState([]);

  useEffect(() => {
    const combined = unionBy(selectedProjects, listOfProjects, 'id');
    if (JSON.stringify(combined) !== JSON.stringify(combinedProjects)) {
      setCombinedProjects(combined);
      setVisibleItems(getSortedProjects(combined, selectedProjects));
    }
  }, [selectedProjects, listOfProjects, combinedProjects]);

  const setSortedVisibleItems = items => {
    setVisibleItems(sortFilteredProjects(items, combinedProjects));
  };

  const setSelectedProjects = currentSelection => {
    setState({
      key: 'Project',
      value: {
        ...state.Project,
        data: {
          ...get(state, 'Project.data', {}),
          selectedProjects: currentSelection,
        },
        error: false,
        errorMessage: null,
      },
    });
    setDeleteProjectModal(deleteProjectModalInitialState);
  };

  const handleFilterChange = ({ detail }) => {
    const newText = detail.filteringText;
    setFilteringText(newText);
    if (newText === '') {
      setSortedVisibleItems(combinedProjects);
    } else {
      const filteredItems = filter(combinedProjects, item =>
        item.name.toLowerCase().includes(newText.toLowerCase()),
      );
      setSortedVisibleItems(filteredItems);
    }
  };

  const confirmDelete = ({ projectIdVal }) => {
    const projectId = isEmpty(projectIdVal)
      ? get(deleteProjectModal, 'selectedProject.projectId', '')
      : projectIdVal;
    const request = {
      body: {
        operation: 'Delete',
        projectId,
        clientId: derivedClientId,
      },
    };
    addContextToPayload(VALARI_API, CREATE_PROJECT, studyPeriod, request).then(response => {
      if (response.status === 200) {
        const updatedProjectList = pageContents.Project.projectList.filter(project => {
          return project.projectId !== projectId;
        });

        const updatedSelectedList = filter(
          get(state, 'Project.data.selectedProjects', []),
          ({ id }) => id !== projectId,
        );

        setPageElements({
          ...pageContents,
          Project: {
            ...pageContents.Project,
            projectList: [...updatedProjectList],
          },
        });
        setState({
          key: 'Project',
          value: {
            ...state.Project,
            data: {
              ...state.Project.data,
              selectedProjects: [...updatedSelectedList],
            },
          },
        });
        setVisibleItems(
          getSortedProjects(
            map(updatedProjectList, mappedProjectToSelectedProject),
            updatedSelectedList,
          ),
        );
        if (!(updatedSelectedList.length > 0)) {
          pushNotification({
            type: NOTIFICATION_TYPE_ERROR,
            content: 'You must select at least one project.',
            id: uuidv4(),
          });
        }
        pushNotification({
          type: NOTIFICATION_TYPE_INFO,
          content: (
            <>
              Project <b>{projectData.projectTitle}</b> has been deleted.
            </>
          ),
          id: uuidv4(),
        });
        setProjectData(prevState => {
          return {
            ...prevState,
            ...projectDataInitialState,
          };
        });
      }
    });
    setDeleteProjectModal(deleteProjectModalInitialState);
  };

  const onConfirmHandler = () => {
    switch (deleteProjectModal.type) {
      case modalTypes.REMOVE: {
        confirmDelete({ projectIdVal: deleteProjectModal.selectedProject.projectId });
        break;
      }
      case modalTypes.UNSELECT: {
        setVisibleItems(getSortedProjects(listOfProjects, deleteProjectModal.newProjects));
        setSelectedProjects(deleteProjectModal.newProjects);
        break;
      }
      default:
    }
  };

  const projectSelectionHandler = ({ detail }) => {
    const currentSelection = get(detail, 'selectedItems');

    const sanitizedSelection = currentSelection.map(({ costCenter, ...rest }) => ({
      ...rest,
    }));

    const prevSelection = get(state, 'Project.data.selectedProjects', []);
    if (size(sanitizedSelection) < size(prevSelection)) {
      const unSelectedProject = difference(prevSelection, sanitizedSelection)[0];
      if (isProjectAllocationPresent(projectAllocation, unSelectedProject.id)) {
        setDeleteProjectModal({
          visible: true,
          selectedProject: unSelectedProject,
          newProjects: sanitizedSelection,
          type: modalTypes.UNSELECT,
        });
      } else {
        setVisibleItems(getSortedProjects(listOfProjects, sanitizedSelection));
        setSelectedProjects(sanitizedSelection);
      }
    } else {
      setVisibleItems(getSortedProjects(listOfProjects, sanitizedSelection));
      setSelectedProjects(sanitizedSelection);
    }
  };

  const openModal = () => {
    setProjectData(prevState => {
      return {
        ...prevState,
        addProjectModalState: true,
        projectDeleteModalState: false,
        projectDetailsModalState: false,
      };
    });
  };

  const closeModal = () => {
    setProjectData(projectDataInitialState);
  };

  const isProjectSelected = projectId => {
    return selectedProjects.find(project => project.id === projectId);
  };

  const addProject = () => {
    if (projectData.projectId) {
      const request = {
        body: {
          operation: 'Update',
          projectDescription: projectData.projectDesc,
          projectName: projectData.projectTitle,
          projectId: projectData.projectId,
          clientId: derivedClientId,
        },
      };
      addContextToPayload(VALARI_API, CREATE_PROJECT, studyPeriod, request).then(response => {
        if (response.status === 200) {
          const updatedProjectList = pageContents.Project.projectList.map(project => {
            return project.projectId === projectData.projectId
              ? JSON.parse(get(response, 'body.data', {}))
              : project;
          });
          setPageElements({
            ...pageContents,
            Project: {
              ...pageContents.Project,
              projectList: [...updatedProjectList],
            },
          });
          setProjectData(prevState => {
            return {
              ...prevState,
              ...projectDataInitialState,
            };
          });
          pushNotification({
            type: NOTIFICATION_TYPE_SUCCESS,
            content: 'Project updated successfully.',
            id: uuidv4(),
          });
          let updatedSelectedProject = selectedProjects;
          if (isProjectSelected(projectData.projectId)) {
            updatedSelectedProject = selectedProjects.map(project => {
              return project.id === projectData.projectId
                ? mappedProjectToSelectedProject(JSON.parse(get(response, 'body.data', {})))
                : project;
            });
            setState({
              key: 'Project',
              value: {
                ...state.Project,
                data: { selectedProjects: [...updatedSelectedProject] },
              },
            });
          }
          setVisibleItems(
            getSortedProjects(
              map(updatedProjectList, mappedProjectToSelectedProject),
              updatedSelectedProject,
            ),
          );
        }
      });
    } else {
      const request = {
        body: {
          operation: 'Create',
          projectDescription: projectData.projectDesc,
          projectName: projectData.projectTitle,
          smeEmployeeId: surveyDetails.userId,
          surveyCostCenter: surveyDetails.surveyCostCenter,
          surveyAmazonOFACostCenterNumber: surveyDetails.surveyAmazonOFACostCenterNumber,
          surveyId: surveyDetails.surveyId,
          surveyCompanyOfaCode: surveyDetails.surveyCompanyOfaCode,
          businessUnit: surveyDetails.businessUnit,
          clientId: derivedClientId,
        },
      };
      addContextToPayload(VALARI_API, CREATE_PROJECT, studyPeriod, request).then(response => {
        if (response.status === 200) {
          const updatedProjectList = [
            JSON.parse(get(response, 'body.data', {})),
            ...pageContents.Project.projectList,
          ];
          const updatedSelectedProject = [
            mappedProjectToSelectedProject(JSON.parse(get(response, 'body.data', {}))),
            ...get(state, 'Project.data.selectedProjects', []),
          ];
          setPageElements({
            ...pageContents,
            Project: {
              ...pageContents.Project,
              projectList: updatedProjectList,
            },
          });
          setState({
            key: 'Project',
            value: {
              ...state.Project,
              data: {
                ...get(state, 'Project.data', {}),
                selectedProjects: updatedSelectedProject,
              },
            },
          });
          setProjectData(prevState => {
            return {
              ...prevState,
              ...projectDataInitialState,
            };
          });
          setVisibleItems(
            getSortedProjects(
              map(updatedProjectList, mappedProjectToSelectedProject),
              updatedSelectedProject,
            ),
          );
          pushNotification({
            type: NOTIFICATION_TYPE_SUCCESS,
            content: 'Project created successfully.',
            id: uuidv4(),
          });
        }
      });
    }
    closeModal();
  };

  const deleteProject = () => {
    let projectName = '';
    const updatedSelectedList = get(state, 'Project.data.selectedProjects', []).filter(project => {
      if (project.id === projectData.projectId) {
        projectName = project.name;
      }
      return project.id !== projectData.projectId;
    });
    if (
      isProjectAllocationPresent(projectAllocation, projectData.projectId) &&
      !isEmpty(projectName)
    ) {
      setDeleteProjectModal({
        visible: true,
        selectedProject: {
          projectName,
          projectId: projectData.projectId,
        },
        newProjects: updatedSelectedList,
        type: modalTypes.REMOVE,
      });
    } else {
      confirmDelete({ projectIdVal: projectData.projectId });
    }
  };

  const setTitle = title => {
    setProjectData(prevState => {
      return {
        ...prevState,
        projectTitle: title.substring(0, Math.min(title.length, 150)),
      };
    });
  };

  const setDescription = projectDesc => {
    setProjectData(prevState => {
      return {
        ...prevState,
        projectDesc,
      };
    });
  };

  const openProjectDetailsModal = (event, project) => {
    setProjectData(prevState => {
      return {
        ...prevState,
        addProjectModalState: false,
        projectDeleteModalState: false,
        projectDetailsModalState: true,
        projectId: project.id,
        projectTitle: project.name,
        projectDesc: project.description,
        isUserCreated: project.isUserCreated,
      };
    });
  };

  const openProjectDeleteModal = () => {
    setProjectData(prevState => {
      return {
        ...prevState,
        addProjectModalState: false,
        projectDeleteModalState: true,
        projectDetailsModalState: false,
      };
    });
  };

  const getProjectDetailsFooter = () => {
    if (projectData.isUserCreated === 'true') {
      return (
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="link"
              onClick={openProjectDeleteModal}
              disabled={isReadOnly(surveyDetails)}
            >
              Delete project
            </Button>
            <Button variant="normal" onClick={openModal} disabled={isReadOnly(surveyDetails)}>
              Edit project
            </Button>
          </SpaceBetween>
        </Box>
      );
    }
    return '';
  };

  // Pagination logic
  const handlePageChange = ({ detail }) => {
    setCurrentPage(detail.currentPageIndex);
  };

  const paginatedItems = visibleItems
    .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    .map(item => ({
      ...item,
      costCenter: item.costCenter || surveyDetails.surveyCostCenter || 'N/A',
    }));

  return (
    <SpaceBetween size="m" className="project-step">
      <Modal
        onDismiss={closeModal}
        visible={projectData.addProjectModalState}
        closeAriaLabel="Close modal"
        size="medium"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={closeModal}>
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={!projectData.projectTitle}
                onClick={() => {
                  addProject();
                }}
              >
                Save
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={projectData.projectId ? 'Project details' : 'Create Project'}
      >
        <FormField label="Project Name">
          <Input
            type="text"
            value={projectData.projectTitle}
            onChange={({ detail }) => setTitle(detail.value)}
          />
        </FormField>
        <br />
        <FormField label="Project Description - optional">
          <Input
            type="text"
            value={projectData.projectDesc}
            onChange={({ detail }) => setDescription(detail.value)}
          />
        </FormField>
      </Modal>

      <Modal
        onDismiss={closeModal}
        visible={projectData.projectDetailsModalState}
        closeAriaLabel="Close modal"
        size="medium"
        footer={getProjectDetailsFooter()}
        header="Project details"
      >
        <FormField label="Project name">
          <p>{projectData.projectTitle}</p>
        </FormField>
        <br />
        <FormField label="Project description">
          <p>{projectData.projectDesc}</p>
        </FormField>
      </Modal>

      <Modal
        onDismiss={closeModal}
        visible={projectData.projectDeleteModalState}
        closeAriaLabel="Close modal"
        size="medium"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={closeModal}>
                Cancel
              </Button>
              <Button variant="normal" onClick={deleteProject}>
                Delete project
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="Delete project"
      >
        <div>
          Are you sure you want to delete <b>{projectData.projectTitle}</b>?
        </div>
      </Modal>

      <div className="project-selection-table">
        <Table
          renderAriaLive={({ firstIndex, lastIndex, totalItemsCount }) =>
            `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
          }
          onSelectionChange={projectSelectionHandler}
          isItemDisabled={() => isReadOnly(surveyDetails)}
          selectedItems={selectedProjects}
          ariaLabels={{
            selectionGroupLabel: 'Items selection',
            allItemsSelectionLabel: ({ selectedItems }) =>
              `${selectedItems.length} ${selectedItems.length === 1 ? 'item' : 'items'} selected`,
            itemSelectionLabel: ({ selectedItems }, item) =>
              `${item.name} is ${selectedItems.length === 1 ? 'selected' : 'deselected'}`,
            // todo not showing correct label when hovering on checkbox.
          }}
          columnDefinitions={[
            {
              id: 'projectName',
              header: 'Project name',
              cell: item => (
                <Link onFollow={event => openProjectDetailsModal(event, item)}>{item.name}</Link>
              ),
              sortingField: 'name',
              isRowHeader: true,
            },
            {
              id: 'costCenter',
              header: 'Cost center',
              cell: item => item.costCenter || surveyDetails.surveyCostCenter || 'N/A',
              sortingField: 'costCenter',
            },
          ]}
          items={paginatedItems}
          loadingText="Loading projects"
          selectionType="multi"
          trackBy="id"
          empty={
            <Box textAlign="center" color="inherit">
              <Box padding={{ bottom: 's' }} variant="p" color="inherit">
                No projects to display.
              </Box>
            </Box>
          }
          filter={
            <TextFilter
              filteringPlaceholder="Search project"
              onChange={handleFilterChange}
              filteringText={filteringText}
            />
          }
          header={
            <Header
              variant="h2"
              actions={
                <Button onClick={openModal} disabled={isReadOnly(surveyDetails)}>
                  Create project
                </Button>
              }
            >
              Projects
            </Header>
          }
          pagination={
            <Pagination
              currentPageIndex={currentPage}
              pagesCount={Math.ceil(size(visibleItems) / itemsPerPage)}
              onChange={handlePageChange}
            />
          }
          preferences={
            <CollectionPreferences
              title="Preferences"
              confirmLabel="Confirm"
              cancelLabel="Cancel"
              preferences={{
                pageSize: itemsPerPage,
                contentDisplay: [
                  { id: 'projectName', visible: true },
                  { id: 'costCenter', visible: true },
                ],
              }}
              pageSizePreference={{
                title: 'Page size',
                options: [
                  { value: 10, label: '10 projects' },
                  { value: 20, label: '20 projects' },
                ],
              }}
              onConfirm={updatePreferences}
            />
          }
        />
      </div>

      <UncheckProjectModal
        projectName={get(deleteProjectModal, 'selectedProject.projectName', '')}
        onDismiss={() => setDeleteProjectModal(deleteProjectModalInitialState)}
        isVisible={get(deleteProjectModal, 'visible', false)}
        onConfirm={onConfirmHandler}
      />
    </SpaceBetween>
  );
};

Projects.propTypes = {
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  pageContents: PropTypes.object.isRequired,
  setPageElements: PropTypes.func.isRequired,
  surveyDetails: PropTypes.object.isRequired,
  studyPeriod: PropTypes.string.isRequired,
  pushNotification: PropTypes.func,
  projectAllocation: PropTypes.object.isRequired,
  derivedClientId: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  studyPeriod: getStudyPeriod(state),
  projectAllocation: get(state, 'projectAllocation', {}),
  derivedClientId: get(state, 'derivedClientId.derivedClientId', undefined),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      pushNotification: setPageNotification,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
