import React, { useEffect, useState } from 'react';
import { Alert, Container, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { get, map } from 'lodash';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import ComponentHeader from './components/ComponentHeader';
import AssignmentDetail from './components/AssignmentDetail';
import isSurveyLockAcquired from './utils';

const SurveyDetail = ({ assignees, navigateTo, isPrimaryAssignee }) => {
  const [jsxToRenderSurveyInUseWarning, setJsxToRenderSurveyInUseWarning] = useState([]);
  const phoneToolLink = 'https://phonetool.amazon.com/users/';

  useEffect(() => {
    const fetchData = () => {
      const promises = assignees.map(assignee => {
        const surveyLockDetails = get(assignee, 'surveyLockDetails', {});

        if (surveyLockDetails) {
          return isSurveyLockAcquired(surveyLockDetails)
            .then(isLockAcquired => {
              if (isLockAcquired) {
                return (
                  <Alert type="error" header={`${assignee.surveyType} survey is in use`}>
                    <Link
                      external
                      href={`${phoneToolLink}${surveyLockDetails.lockAcquiredByAlias}`}
                    >
                      {`@${surveyLockDetails.lockAcquiredByAlias}`}
                    </Link>
                    {' is working on the survey. Only one user can access the survey at a time.'}
                  </Alert>
                );
              }
              return <div key="randomKey1" />;
            })
            .catch(error => {
              // Handle errors if needed
              // eslint-disable-next-line no-console
              console.error('An error occurred:', error);
            });
        }

        return Promise.resolve(null);
      });

      Promise.all(promises)
        .then(jsxArraySurveyInUseWarning =>
          setJsxToRenderSurveyInUseWarning(jsxArraySurveyInUseWarning),
        )
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error('Error fetching data:', error);
        });
    };

    fetchData();
  }, [assignees]);

  return (
    <SpaceBetween size="m">
      {jsxToRenderSurveyInUseWarning}
      {map(assignees, assignee => {
        return (
          <Container
            key={assignee.surveyId}
            disableContentPaddings
            header={
              <ComponentHeader
                headerDetails={assignee}
                navigateTo={navigateTo}
                surveyId={assignee.surveyId}
              />
            }
            footer={<AssignmentDetail assignee={assignee} isPrimaryAssignee={isPrimaryAssignee} />}
          />
        );
      })}
    </SpaceBetween>
  );
};

SurveyDetail.propTypes = {
  isPrimaryAssignee: PropTypes.bool,
  assignees: PropTypes.array.isRequired,
  navigateTo: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => {
  return {
    navigateTo: payload => dispatch(push(payload)),
  };
};

export default connect(null, mapDispatchToProps)(SurveyDetail);
