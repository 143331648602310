import { get, isEmpty } from 'lodash';
import { getEntityListPath, SURVEY_TYPE } from '../../../../common/constants/surveyType';

const validateProjectAllocation = (pageElements, surveyType, projectAllocation) => {
  const entityList = get(pageElements, getEntityListPath(surveyType), []);
  const project = projectAllocation.data;

  if (isEmpty(project) || Object.keys(project).length !== entityList.length) return false;
  let validation = true;
  Object.keys(project).forEach(employee => {
    if (
      surveyType === SURVEY_TYPE.LABOR_DUAL
        ? Number(project[employee].total) < 0 || Number(project[employee].total) > 100
        : Number(project[employee].total) !== 100
    ) {
      validation = false;
    }
  });
  return validation;
};

export default validateProjectAllocation;
