import React, { useEffect, useState } from 'react';
import { get, isEmpty, isNull, isUndefined, map } from 'lodash';
import {
  Header,
  Container,
  Checkbox,
  Alert,
  Button,
  Input,
  SpaceBetween,
  ColumnLayout,
  StatusIndicator,
} from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { omitBy } from 'lodash/object';
import { errorMessages } from '../../../constants/steps-config';
import { getAttestationContent, getAttestationSlice } from '../utils/attestations_list';
import TEMPLATE_IDS from '../../../../../common/constants/templateIds';
import { getSubmitAttestationConfig } from '../../../../../common/constants/surveyType';
import { isReadOnly } from '../../../utils/survey_page_utils';
import { setSubmitAttestation as setSubmitAttestationAction } from '../../../redux/actions/submitAttestationActions';
import validateSubmit from '../../../constants/validations/Submit';
import { DEFAULT_BUSINESS_ID } from '../../../../../common/config/api_constants';
// eslint-disable-next-line import/no-cycle
import stepsFn from '../../../constants/SurveySteps';

const Submit = ({
  state,
  setState,
  templateId,
  surveyId,
  surveyType,
  surveyDetails,
  projectAllocation,
  activityDualAllocation,
  activityAllocation,
  submitAttestation,
  derivedClientId,
}) => {
  const dispatch = useDispatch();
  const attestationConfig = getSubmitAttestationConfig(surveyType);
  const [isVisible, setIsVisible] = useState({
    textArea: get(omitBy(submitAttestation, isNull), 'data', []).includes(
      getAttestationContent(attestationConfig.attestationEmployeeListId, surveyType),
    ),
    info: get(omitBy(submitAttestation, isNull), 'data', []).includes(
      getAttestationContent(attestationConfig.attestationInfoId, surveyType),
    ),
  });
  const steps = stepsFn(templateId, surveyType, derivedClientId);
  const stepKeys = steps.map(each => each.stateKey);

  const setSubmitAttestation = val => {
    dispatch(
      setSubmitAttestationAction({
        ...val,
        error: !validateSubmit(surveyId, val, surveyType),
      }),
    );
  };

  useEffect(() => {
    setSubmitAttestation(submitAttestation);
  }, []);

  const textBoxContentHandler = ({ detail }) => {
    setSubmitAttestation({
      ...submitAttestation,
      textbox: detail.value,
    });
  };

  function onChangeHandler(event, content) {
    const { detail } = event;
    const value = detail.checked;
    let textBox = get(submitAttestation, 'textbox');
    switch (content) {
      case getAttestationContent(attestationConfig.attestationEmployeeListId, surveyType): {
        setIsVisible({ ...isVisible, textArea: value });
        if (!value) textBox = '';
        break;
      }
      case getAttestationContent(attestationConfig.attestationInfoId, surveyType): {
        setIsVisible({ ...isVisible, info: value });
        break;
      }
      default:
    }
    if (!value) {
      const filteredArray = submitAttestation.data.filter(attestation => attestation !== content);
      setSubmitAttestation({
        ...submitAttestation,
        data: [...filteredArray],
        textbox: textBox,
      });
    } else {
      setSubmitAttestation({
        ...submitAttestation,
        data: [...submitAttestation.data, content],
      });
    }
  }

  const { data } = submitAttestation;

  const showErrors = steps
    .filter(eachStep => {
      switch (eachStep.stateKey) {
        case 'ProjectAllocation':
        case 'AdjustProjectAllocation':
          return projectAllocation.error === true;
        case 'ActivityDualAllocation':
          return activityDualAllocation.error === true;
        case 'ActivityAllocation':
          return activityAllocation.error === true;
        case 'Submit':
          return submitAttestation.error === true;
        default:
          return (
            isNull(state[eachStep.stateKey]) ||
            isUndefined(state[eachStep.stateKey]) ||
            state[eachStep.stateKey].error === true
          );
      }
    })
    .map(eachStep => eachStep.stateKey);

  const goToPage = page => {
    const step = steps.findIndex(eachStep => eachStep.stateKey === page);
    setState({
      key: 'activeStep',
      value: step,
    });
  };

  const getCheckBoxes = list => {
    return map(list, attestation => {
      const { id, content } = attestation;
      return (
        <div style={{ marginBottom: '5px' }} key={id}>
          <Checkbox
            onChange={event => onChangeHandler(event, content)}
            checked={data.includes(content)}
            disabled={isReadOnly(surveyDetails)}
          >
            {content}
          </Checkbox>
        </div>
      );
    });
  };

  const getErrorMessages = errorPages => {
    if (
      errorPages === 'Submit' &&
      get(submitAttestation, 'data', []).includes(
        getAttestationContent(attestationConfig.attestationEmployeeListId, surveyType),
      )
    )
      return get(errorMessages, [surveyType, 'EntityNames']);
    return get(errorMessages, [surveyType, errorPages]);
  };

  const submitAPIError = get(state, 'SubmitAPI.error', null);

  const employeesReviewedCount = Object.keys(projectAllocation.data).length;
  const selectedProjectsCount = get(state, 'Project.data.selectedProjects', []).length;
  const employeeProjectMatrixReviewStatus = employeesReviewedCount > 0;
  const selectActivitiesCount = Object.keys(get(state, 'Activity.data.selectedActivities', []))
    .length;
  const employeeActivityDualAllocationMatrixReviewStatus =
    Object.keys(activityDualAllocation.data).length > 0;
  const employeeActivityAllocationMatrixReviewStatus =
    Object.keys(activityAllocation.data).length > 0;
  const uploadedDocumentCount = Object.values(get(state, 'ProjectDocuments.data', {})).reduce(
    (total, documents) => {
      return total + documents.length;
    },
    0,
  );

  const activityAllocationErrored = () => {
    // todo update this condition as per clientId & surveyType
    if (DEFAULT_BUSINESS_ID === 'RadicalUK') {
      return (
        !employeeActivityDualAllocationMatrixReviewStatus ||
        showErrors.includes('ActivityDualAllocation')
      );
    }
    return (
      !employeeActivityAllocationMatrixReviewStatus || showErrors.includes('ActivityAllocation')
    );
  };

  return (
    <div>
      <SpaceBetween size="xs">
        {submitAPIError && (
          <Alert visible="true" dismissAriaLabel="Close alert" type="error">
            {submitAPIError}
          </Alert>
        )}
        {showErrors.map(errorPageName => {
          return (
            get(errorMessages, [surveyType, errorPageName]) && (
              <Alert
                key={errorPageName}
                visible="true"
                dismissAriaLabel="Close alert"
                type="error"
                action={
                  errorPageName === 'Submit' || (
                    <Button onClick={() => goToPage(errorPageName)}>Resolve</Button>
                  )
                }
                header={getErrorMessages(errorPageName)}
              />
            )
          );
        })}
        {!isReadOnly(surveyDetails) && (
          <Container header={<Header variant="h2">Review</Header>}>
            <ColumnLayout columns={3}>
              {stepKeys.includes('Employee') && (
                <div>
                  <Header variant="h3">Review employee list</Header>
                  <StatusIndicator type={employeesReviewedCount > 0 ? 'success' : 'error'}>
                    {/* eslint-disable-next-line max-len */}
                    {employeesReviewedCount}{' '}
                    {employeesReviewedCount === 1 ? 'employee' : 'employees'} reviewed
                  </StatusIndicator>
                </div>
              )}
              {stepKeys.includes('Project') && (
                <div>
                  <Header variant="h3">Select projects</Header>
                  <StatusIndicator type={selectedProjectsCount > 0 ? 'success' : 'error'}>
                    {/* eslint-disable-next-line max-len */}
                    {selectedProjectsCount} {selectedProjectsCount === 1 ? 'project' : 'projects'}{' '}
                    selected
                  </StatusIndicator>
                </div>
              )}
              {(stepKeys.includes('ProjectAllocation') ||
                stepKeys.includes('AdjustProjectAllocation')) && (
                <div>
                  <Header variant="h3">Fill employee project matrix</Header>
                  <StatusIndicator
                    type={
                      employeeProjectMatrixReviewStatus && !showErrors.includes('ProjectAllocation')
                        ? 'success'
                        : 'error'
                    }
                  >
                    {employeeProjectMatrixReviewStatus && !showErrors.includes('ProjectAllocation')
                      ? 'Complete'
                      : 'Incomplete'}
                  </StatusIndicator>
                </div>
              )}
              {stepKeys.includes('Activity') && (
                <div>
                  <Header variant="h3">Select activities</Header>
                  <StatusIndicator type={selectActivitiesCount > 0 ? 'success' : 'error'}>
                    {/* eslint-disable-next-line max-len */}
                    {selectActivitiesCount}{' '}
                    {selectActivitiesCount === 1 ? 'activity' : 'activities'} selected
                  </StatusIndicator>
                </div>
              )}
              {(stepKeys.includes('ActivityAllocation') ||
                stepKeys.includes('ActivityDualAllocation')) && (
                <div>
                  <Header variant="h3">Fill employee activity matrix</Header>
                  <StatusIndicator type={activityAllocationErrored() ? 'error' : 'success'}>
                    {activityAllocationErrored() ? 'Incomplete' : 'Complete'}
                  </StatusIndicator>
                </div>
              )}
              {steps.includes('ProjectDocuments') && (
                <div>
                  <Header variant="h3">Upload documentation</Header>
                  <StatusIndicator type="success">
                    {/* eslint-disable-next-line max-len */}
                    {uploadedDocumentCount} {uploadedDocumentCount === 1 ? 'document' : 'documents'}{' '}
                    selected
                  </StatusIndicator>
                </div>
              )}
            </ColumnLayout>
          </Container>
        )}
        <Container
          className="allocation-container"
          header={<Header variant="h2">Attestation</Header>}
        >
          <SpaceBetween size="s">
            <div>Select all the methodology(s) you used to complete the survey.</div>
            <div>
              {getCheckBoxes(
                getAttestationSlice(surveyType, 0, attestationConfig.defaultAttestationEndIndex),
              )}
              {isVisible.info && TEMPLATE_IDS.includes(templateId) ? (
                <Alert type="info">
                  Make sure to provide the documents you reviewed in Step 6 Upload documentation.
                </Alert>
              ) : (
                <div />
              )}
              {getCheckBoxes(
                getAttestationSlice(
                  surveyType,
                  attestationConfig.defaultAttestationEndIndex,
                  attestationConfig.consultAttestationEndIndex,
                ),
              )}
              {isVisible.textArea ? (
                <Input
                  onChange={textBoxContentHandler}
                  value={get(submitAttestation, 'textbox', '')}
                  placeholder="Enter comma separated employee names"
                  invalid={isEmpty(get(submitAttestation, 'textbox', ''))}
                  disabled={isReadOnly(surveyDetails)}
                />
              ) : (
                <div />
              )}
            </div>
          </SpaceBetween>
        </Container>
        {!isReadOnly(surveyDetails) && (
          <Alert statusIconAriaLabel="Warning" type="warning">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            You won't be able to change your answers after submitting the survey.
          </Alert>
        )}
      </SpaceBetween>
    </div>
  );
};

Submit.propTypes = {
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  templateId: PropTypes.string.isRequired,
  surveyId: PropTypes.string.isRequired,
  surveyType: PropTypes.string.isRequired,
  surveyDetails: PropTypes.object.isRequired,
  activityDualAllocation: PropTypes.object.isRequired,
  activityAllocation: PropTypes.object.isRequired,
  projectAllocation: PropTypes.object.isRequired,
  submitAttestation: PropTypes.object.isRequired,
  derivedClientId: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  surveyId: get(state, 'router.location.state.headerDetails.surveyId'),
  activityDualAllocation: get(state, 'activityDualAllocation', {}),
  activityAllocation: get(state, 'activityAllocation', {}),
  projectAllocation: get(state, 'projectAllocation', {}),
  submitAttestation: get(state, 'submitAttestation', {}),
  derivedClientId: get(state, 'derivedClientId.derivedClientId', undefined),
});

export default connect(mapStateToProps)(Submit);
