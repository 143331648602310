import * as React from 'react';
import {
  Wizard,
  Box,
  ColumnLayout,
  Link,
  Popover,
  SpaceBetween,
  Spinner,
  Button,
  TextContent,
  Header,
} from '@amzn/awsui-components-react/polaris';
import { connect, useSelector } from 'react-redux';
import { get, isUndefined, max } from 'lodash';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { useState } from 'react';
import useTools from './components/WizardTools/WizardTools';
import useWizard from './components/TWizard/TWizard';
import { descriptions, TOOLS_CONTENT_FN } from './constants/steps-config';
import PageHeader from '../../common/components/page_header/PageHeader';
import SurveySteps from './constants/SurveySteps';
import Page from '../../common/components/with_page/Page';
import { RESOURCE } from '../auth/constants/rbac_constants';
import { getRoutePathWithStudyPeriod, ROUTE_PATH } from '../../common/constants/route_paths';
import { getStudyPeriod } from '../../common/constants/study_period';
import { getBusinessUnit, isReadOnly } from './utils/survey_page_utils';
import { saveSurveyActions } from './redux/saveSurvey';
import { getBusinessDate, getDate } from '../../common/utils/date_time_util';
import './css/index.css';
import { updateSurveyLockAction } from '../landing/common/redux/UpdateSurveyLock';
import { getSurveyType } from '../../common/constants/surveyType';
import metricsUtility from '../../common/components/metrics/MetricsUtility';
import {
  METRICS_METHOD,
  METRICS_NAME,
  METRICS_PAGE,
} from '../../common/components/metrics/metrics_constants';
import { createStringMetric } from '../../common/components/metrics/katal_metrics';
import SurveyInactivityTimer from './components/SurveySessionManager/SurveyInactivityTimer';
// import useAutoSave from './components/SurveySessionManager/useAutosave';
import SaveInformation from './components/SaveInformation/SaveInformation';
import useSessionExtension from './components/SurveySessionManager/useSessionExtension';
import { DEFAULT_BUSINESS_ID } from '../../common/config/api_constants';

const SurveyPage = ({
  surveyContext,
  navigateTo,
  currentDashboard,
  saveSurvey,
  surveyId,
  secondaryAssigneesMap,
  saveSurveyData,
  studyPeriod,
  updateSurveyLockData,
  updateSurveyLock,
  surveyType,
  projectAllocation,
  activityDualAllocation,
  activityAllocation,
  derivedClientId,
  submitAttestation,
}) => {
  const [loading, setLoading] = useState(true);
  const dashboardPath = get(currentDashboard, 'routePath', ROUTE_PATH.DASHBOARD);
  const isFechingDetails = useSelector(state =>
    get(state, 'entities.surveyDetails.isFetching', false),
  );
  const isSavingSurvey = useSelector(state => get(state, 'entities.saveSurvey.isFetching', false));
  const isSaveInProgress = isFechingDetails || isSavingSurvey;

  const redirectToDashboard = !surveyContext.data;
  if (redirectToDashboard) navigateTo(getRoutePathWithStudyPeriod(dashboardPath, studyPeriod));

  React.useEffect(() => {
    if (redirectToDashboard || isUndefined(surveyId)) {
      return;
    }
    metricsUtility.registerPage(
      METRICS_PAGE.SURVEY,
      createStringMetric(METRICS_NAME.SURVEY_ID, surveyId),
    );
    metricsUtility.registerMethod(METRICS_PAGE.SURVEY, METRICS_METHOD.SURVEY_WIZARD);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyId]);

  const surveyDetails = get(surveyContext, ['data', surveyId], {});
  const taxYear = get(surveyDetails, 'year', '');
  const templateId = get(surveyDetails, 'templateDocumentId', '');
  const secondaryAssigneesList = get(secondaryAssigneesMap, [surveyId], []);
  const {
    toolsContent,
    isToolsOpen,
    openTools,
    closeTools,
    setFormattedToolsContent,
    onToolsChange,
  } = useTools(templateId, surveyType, derivedClientId);

  const TOOLS_CONTENT = TOOLS_CONTENT_FN(templateId);

  const I18N_STRINGS = {
    stepNumberLabel: stepNumber => `Step ${stepNumber}`,
    collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
    previousButton: 'Previous',
    nextButton: 'Next',
    submitButton: isReadOnly(surveyDetails) ? 'Close' : 'Submit',
    optional: 'optional',
  };

  function getPayloadOverridesFromUserResponse() {
    return {
      ProjectAllocation: {
        ...projectAllocation,
      },
      ActivityDualAllocation: {
        ...activityDualAllocation,
      },
      ActivityAllocation: {
        ...activityAllocation,
      },
      Submit: {
        ...submitAttestation,
      },
    };
  }

  const {
    surveyInfo,
    pageElements,
    setActiveStepIndexAndCloseTools,
    changeStateHandler,
    onNavigate,
    onCancel,
    onSubmit,
    setPageElements,
    validationState,
    setValidationState,
  } = useWizard({
    closeTools,
    setFormattedToolsContent,
    surveyDetails,
    navigateTo,
    currentDashboard,
    saveSurvey,
    templateId,
    secondaryAssigneesList,
    surveyId,
    studyPeriod,
    updateSurveyLockData,
    updateSurveyLock,
    surveyType,
    activityAllocation,
    activityDualAllocation,
    projectAllocation,
    submitAttestation,
    derivedClientId,
    setLoading,
  });
  // useAutoSave(surveyInfo, 3000);

  useSessionExtension();
  const SURVEY_STEPS = SurveySteps(templateId, surveyType, derivedClientId);
  const wizardSteps = SURVEY_STEPS.map(({ title, stateKey, StepContent, isOptional }) => ({
    title,
    info: <Link variant="info" onFollow={() => openTools(TOOLS_CONTENT[stateKey].default)} />,

    description: descriptions(
      stateKey,
      get(surveyDetails, 'year', ''),
      surveyType,
      derivedClientId,
    ),
    content: (
      <StepContent
        state={surveyInfo}
        pageContents={pageElements}
        setPageElements={setPageElements}
        setState={changeStateHandler}
        openTools={openTools}
        setActiveStepIndex={setActiveStepIndexAndCloseTools}
        surveyDetails={surveyDetails}
        templateId={templateId}
        surveyType={surveyType}
        validationState={validationState}
        setValidationState={setValidationState}
        onNavigate={event => onNavigate(event, getPayloadOverridesFromUserResponse())}
      />
    ),
    isOptional,
  }));
  const { surveyEndDate } = surveyDetails;
  const SurveyInformation = () => {
    const {
      templateDocumentId,
      surveyCostCenter,
      lastUpdatedBy,
      surveyCostCenterName,
      userName,
      originalUserName,
      businessUnit,
    } = surveyDetails;

    return (
      <ColumnLayout columns={2}>
        <SpaceBetween size="xxxs">
          <Box variant="p" color="text-body-secondary">
            Primary assignee
          </Box>
          <Box variant="p">{userName}</Box>
        </SpaceBetween>
        <SpaceBetween size="xxxs">
          <Box variant="p" color="text-body-secondary">
            Original assignee
          </Box>
          <Box variant="p">{originalUserName}</Box>
        </SpaceBetween>
        <SpaceBetween size="xxxs">
          <Box variant="p" color="text-body-secondary">
            Survey template
          </Box>
          <Box variant="p">{templateDocumentId}</Box>
        </SpaceBetween>
        <SpaceBetween size="xxxs">
          <Box variant="p" color="text-body-secondary">
            Business Unit
          </Box>
          <Box variant="p">{getBusinessUnit(businessUnit)}</Box>
        </SpaceBetween>
        <SpaceBetween size="xxxs">
          <Box variant="p" color="text-body-secondary">
            Cost center number
          </Box>
          <Box variant="p">{surveyCostCenter}</Box>
        </SpaceBetween>
        <SpaceBetween size="xxxs">
          <Box variant="p" color="text-body-secondary">
            Cost center name
          </Box>
          <Box variant="p">{surveyCostCenterName}</Box>
        </SpaceBetween>
        <SpaceBetween size="xxxs">
          <Box variant="p" color="text-body-secondary">
            Due date
          </Box>
          <Box variant="p">{getBusinessDate(surveyEndDate)}</Box>
        </SpaceBetween>
        <SpaceBetween size="xxxs">
          <Box variant="p" color="text-body-secondary">
            Last saved by
          </Box>
          <Box variant="p">{lastUpdatedBy}</Box>
        </SpaceBetween>
      </ColumnLayout>
    );
  };

  const getSurveyPayload = () => {
    const activeStepContext = get(
      JSON.parse(get(surveyDetails, 'userResponse', '{}')),
      'activeStep',
      0,
    );
    return {
      clientId: derivedClientId || DEFAULT_BUSINESS_ID,
      surveyId: surveyDetails.surveyId,
      userResponse: JSON.stringify({
        ...surveyInfo,
        activeStep: max([surveyInfo.activeStep, activeStepContext]),
        ...getPayloadOverridesFromUserResponse(),
      }),
      secondaryAssignees: secondaryAssigneesList,
      version: surveyDetails.lastUpdatedOn,
      sessionId: updateSurveyLockData.sessionId,
    };
  };

  const content = (
    <>
      <SurveyInactivityTimer
        isReadOnly={isReadOnly(surveyDetails)}
        surveyPayload={getSurveyPayload()}
        updateSurveyLockData={updateSurveyLockData}
      />
      <SpaceBetween size="m">
        <Page
          isPageAccessControlled
          PageId={RESOURCE.PAGE_SME_SURVEY}
          PageHeader={PageHeader}
          navigationHide
          tools={toolsContent}
          toolsOpen={isToolsOpen}
          onToolsChange={onToolsChange}
          contentType="wizard"
          PageContent={
            <SpaceBetween size="m">
              <div className="survey-page-header-info">
                <Box>
                  <Header variant="h3"> {taxYear} R&D tax credit study</Header>
                </Box>
                <div className="survey-page-header-info-sub-section">
                  <Popover
                    size="large"
                    triggerType="text"
                    header="Survey information"
                    content={<SurveyInformation />}
                  >
                    Due on {getDate(surveyEndDate)}
                  </Popover>
                  <span className="custom-divider"></span>
                  <Box color="text-body-secondary">
                    <TextContent>
                      <SaveInformation />
                    </TextContent>
                  </Box>
                  <span className="custom-divider"></span>
                  <Box color="text-body-secondary">
                    <Button
                      onClick={() => onCancel(getPayloadOverridesFromUserResponse())}
                      disabled={isSaveInProgress}
                    >
                      Save and leave survey
                    </Button>
                  </Box>
                </div>
              </div>
              <div className="wizard-section">
                <Wizard
                  steps={wizardSteps}
                  activeStepIndex={surveyInfo.activeStep}
                  i18nStrings={I18N_STRINGS}
                  onNavigate={event => onNavigate(event, getPayloadOverridesFromUserResponse())}
                  onCancel={() => onCancel(getPayloadOverridesFromUserResponse())}
                  onSubmit={() => onSubmit(getPayloadOverridesFromUserResponse())}
                />
              </div>
              {!saveSurveyData.triggeredByAutosave &&
                (surveyContext.isFetching || saveSurveyData.isFetching || loading) && (
                  <div className="overlay">
                    <div className="spinner-center">
                      <Spinner size="large" />
                    </div>
                  </div>
                )}
            </SpaceBetween>
          }
        />
      </SpaceBetween>
    </>
  );

  return content;
};

SurveyPage.propTypes = {
  surveyContext: PropTypes.object.isRequired,
  navigateTo: PropTypes.func.isRequired,
  currentDashboard: PropTypes.object.isRequired,
  saveSurvey: PropTypes.func.isRequired,
  secondaryAssigneesMap: PropTypes.object.isRequired,
  saveSurveyData: PropTypes.object.isRequired,
  studyPeriod: PropTypes.string.isRequired,
  surveyId: PropTypes.string.isRequired,
  updateSurveyLockData: PropTypes.object.isRequired,
  updateSurveyLock: PropTypes.func.isRequired,
  surveyType: PropTypes.string.isRequired,
  projectAllocation: PropTypes.object.isRequired,
  activityDualAllocation: PropTypes.object.isRequired,
  activityAllocation: PropTypes.object.isRequired,
  derivedClientId: PropTypes.string.isRequired,
  submitAttestation: PropTypes.object.isRequired,
};
const mapStateToProps = state => {
  const surveyId = get(state, 'router.location.state.headerDetails.surveyId');
  return {
    currentDashboard: get(state, 'appMetaData.ui.currentDashboard'),
    surveyId,
    surveyContext: get(state, 'entities.surveyDetails'),
    saveSurveyData: get(state, 'entities.saveSurvey'),
    secondaryAssigneesMap: get(state, 'entities.secondaryAssigneesMap'),
    studyPeriod: getStudyPeriod(state),
    updateSurveyLockData: get(state, 'entities.updateSurveyLock.data.body', {}),
    surveyType: getSurveyType(state, surveyId),
    projectAllocation: get(state, 'projectAllocation', {}),
    activityDualAllocation: get(state, 'activityDualAllocation', {}),
    activityAllocation: get(state, 'activityAllocation', {}),
    derivedClientId: get(state, 'derivedClientId.derivedClientId', undefined),
    submitAttestation: get(state, 'submitAttestation', {}),
  };
};

const mapDispatchToProps = dispatch => {
  const actions = bindActionCreators(
    {
      saveSurvey: saveSurveyActions.BEGIN,
      updateSurveyLock: updateSurveyLockAction.BEGIN,
    },
    dispatch,
  );
  return {
    ...actions,
    navigateTo: payload => dispatch(push(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyPage);
