import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@amzn/awsui-components-react';
import './WizardDescription.css';
import { isBlank } from '../../../../../common/utils/string_utils';

const WizardDescription = ({ stepDescription, stepSummary, stepSummaryHeading }) => {
  return (
    <>
      <br />
      <div className="wizard-description">
        <div className="description-text">{stepDescription}</div>
        {isBlank(stepSummary) || (
          <div className="info-box">
            <Alert statusIconAriaLabel="Info" header={stepSummaryHeading}>
              {stepSummary}
            </Alert>
          </div>
        )}
      </div>
      <br />
    </>
  );
};

WizardDescription.propTypes = {
  stepDescription: PropTypes.node.isRequired,
  stepSummary: PropTypes.string.isRequired,
  stepSummaryHeading: PropTypes.string.isRequired,
};

export default WizardDescription;
