import { Box, Button, Container } from '@amzn/awsui-components-react';
import Amplify, { Auth, Hub } from 'aws-amplify';
import { get, includes, isNull, size } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import qs from 'qs';
import { connect, useDispatch } from 'react-redux';
import './App.css';
import { useHistory } from 'react-router-dom';
import { keys } from 'lodash/object';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import awsAuthConfig from './common/config/amplify.auth';
import amplifyConfig from './common/config/amplify.config';
import { setAuthState } from './redux/actions/authActions';
import Routes from './Routes';
import { updateSurveyLockAction } from './app/landing/common/redux/UpdateSurveyLock';
import SURVEY_LOCK_ACTIONS from './common/constants/survey_lock_actions';
import metricsUtility from './common/components/metrics/MetricsUtility';
import { METRICS_METHOD, METRICS_TIMER } from './common/components/metrics/metrics_constants';
import { getRoutePathWithStudyPeriod, ROUTE_PATH } from './common/constants/route_paths';

Amplify.configure(amplifyConfig);
Auth.configure({ oauth: awsAuthConfig });

const AUTH_STATES = {
  SIGNED_IN: 'signedIn',
  SIGN_IN_ONGOING: 'signIn_OnGoing',
  SIGNED_OUT: null,
};

const isSignInOngoing = location => {
  const queryParamKeys = keys(qs.parse(get(location, 'search'), { ignoreQueryPrefix: true }));
  return (
    size(queryParamKeys) === 2 &&
    includes(queryParamKeys, 'code') &&
    includes(queryParamKeys, 'state')
  );
};

const App = props => {
  const dispatch = useDispatch();
  const { location } = useHistory();
  const {
    updateSurveyLockData,
    updateSurveyLock,
    currentDashboard,
    navigateTo,
    derivedClientId,
  } = props;
  const history = useHistory();

  if (isSignInOngoing(location)) {
    dispatch(setAuthState(AUTH_STATES.SIGN_IN_ONGOING));
  }

  const backToHome = surveyId => {
    const dashboardPath = get(currentDashboard, 'routePath', ROUTE_PATH.DASHBOARD);
    const navigationState = { pathname: getRoutePathWithStudyPeriod(dashboardPath, 2024) };
    if (dashboardPath === ROUTE_PATH.RND_DASHBOARD)
      navigateTo({
        ...navigationState,
        search: `?surveyId=${surveyId}`,
        state: { submitted: false, surveyId },
      });
    else navigateTo({ ...navigationState, state: { submitted: false } });
  };

  /**
   *
   * Restricting the front and back swipe or front and back button click on the SME website.
   * Hence, we don't need to update survey lock or save survey in such scenarios.
   * User will have to click on Save and Leave Button to exit the Survey page.
   */

  useEffect(() => {
    const unblock = history.block((location_, action) => {
      if (action === 'POP') {
        return false; // Block pop (back/forward) navigation
      }
      return true;
    });

    return () => {
      unblock(); // Clean up the blocker when the component unmounts
    };
  }, [history]);

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    window.onbeforeunload = event => {
      Auth.currentAuthenticatedUser()
        .then(
          // eslint-disable-next-line consistent-return
          () => {
            if (!isNull(updateSurveyLockData)) {
              const isLockAcquired = get(updateSurveyLockData, 'isLockAcquired', false);
              if (isLockAcquired === true) {
                const { surveyId } = updateSurveyLockData;
                const e = event || window.event;
                e.preventDefault();
                if (e) {
                  e.returnValue = '';
                  metricsUtility.invalidateTimerAndPublish(
                    METRICS_METHOD.SURVEY_WIZARD,
                    METRICS_TIMER.SURVEY_STEP,
                  );
                  updateSurveyLock({
                    body: {
                      clientId: derivedClientId,
                      surveyId: updateSurveyLockData.surveyId,
                      actionType: SURVEY_LOCK_ACTIONS.Release,
                      sessionId: updateSurveyLockData.sessionId,
                    },
                  });
                }
                backToHome(surveyId);
                return '';
              }
            }
          },
        )
        .catch(() => {});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSurveyLockData, updateSurveyLock]);

  useEffect(() => {
    Auth.currentSession()
      .then(() => dispatch(setAuthState(AUTH_STATES.SIGNED_IN)))
      .catch(() => dispatch(setAuthState(AUTH_STATES.SIGNED_OUT)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI': {
          dispatch(setAuthState(AUTH_STATES.SIGNED_IN));
          break;
        }
        case 'signOut':
        case 'signIn_failure':
        case 'cognitoHostedUI_failure': {
          dispatch(setAuthState(AUTH_STATES.SIGNED_OUT));
          break;
        }
        default:
          break;
      }
    });
  });

  const getContent = () => {
    const { authState } = props;

    if (authState === AUTH_STATES.SIGNED_IN) {
      return <Routes />;
    }

    return (
      <Container
        header={
          <Box textAlign="center" variant="h1">
            Welcome to Amazon R&D tax credit study
          </Box>
        }
        className="sign-in-container"
      >
        <Box textAlign="center">
          <Button
            variant="primary"
            loading={authState === AUTH_STATES.SIGN_IN_ONGOING}
            disabled={authState === AUTH_STATES.SIGN_IN_ONGOING}
            onClick={() => Auth.federatedSignIn()}
          >
            Sign in
          </Button>
        </Box>
      </Container>
    );
  };

  return <div className="app">{getContent()}</div>;
};

App.propTypes = {
  authState: PropTypes.string,
  updateSurveyLockData: PropTypes.object,
  updateSurveyLock: PropTypes.func.isRequired,
  currentDashboard: PropTypes.object.isRequired,
  navigateTo: PropTypes.func.isRequired,
  derivedClientId: PropTypes.string,
};

App.defaultProps = {
  authState: null,
};

const mapStateToProps = state => ({
  authState: get(state, 'auth.authState', null),
  updateSurveyLockData: get(state, 'entities.updateSurveyLock.data.body', null),
  currentDashboard: get(state, 'appMetaData.ui.currentDashboard'),
  derivedClientId: get(state, 'derivedClientId.derivedClientId', undefined),
});

const mapDispatchToProps = dispatch => {
  const actions = bindActionCreators(
    {
      updateSurveyLock: updateSurveyLockAction.BEGIN,
    },
    dispatch,
  );

  return {
    ...actions,
    navigateTo: payload => dispatch(push(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
